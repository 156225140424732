<template>
    <div class="teacher-container">
        <!-- <div class="custom-tree-container">
      <custom-tree @select="handleSelect"></custom-tree>
    </div> -->
        <div class="content-wrap">
            <div class="header">
                <span class="title">{{ currentOrg && currentOrg.Name }}</span>
                <span class="iconfont iconjichushuju-jiaoshirenshu ml-16">{{total}}</span>
            </div>
            <div class="params">
                <div class="left">
                    <el-input placeholder="员工姓名" v-model="queryParams.KeyWord" size="large">
                        <el-button slot="append" icon="el-icon-search" @click="initialize"></el-button>
                    </el-input>
                </div>
                <div class="flex">
                    <el-button type="primary" size="large" icon="el-icon-plus" @click="handleAdd">添加员工</el-button>
                    <!-- <el-button type="default" size="large" @click="handleImport">批量导入</el-button> -->
                    <!-- <el-button :disabled="!userList.length" type="default" size="large" @click="handleCreateAccount">生成账户</el-button> -->
                </div>
            </div>
            <div class="table-container">
                <div class="table-content">
                    <el-table v-loading="loading" :data="dataList" height="100%" @selection-change="handleSelectionChange">
                        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                        <template v-for="(item, i) in tableConfig">
                            <el-table-column :key="i" :label="item.label" :align="item.align" :min-width="item.width">
                                <template slot-scope="scope">
                                    <span v-if="item.prop == 'Oprate'">
                                        <!-- <el-button type="text" size="medium" @click="handleOprateAccount(scope.row)">{{getStatus(scope.row['UserStatus']).oprateBtnText}}</el-button> -->
                                        <el-button type="text" size="medium" @click="handleView(scope.row.Id)">查看</el-button>
                                        <el-button v-if="!scope.row.IsAdmin" type="text" size="medium" @click="handleEdit(scope.row.Id)">修改</el-button>
                                        <el-button v-if="!scope.row.IsAdmin" type="text" size="medium" @click="handleDelete(scope.row)">删除</el-button>
                                    </span>
                                    <span class="info" v-else-if="item.prop == 'Name'">
                                        <el-avatar :size="32" :src="scope.row['Avatar'] || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
                                        <span class="ml-16">{{ scope.row[item.prop] }}</span>
                                    </span>
                                    <span class="info" v-else-if="item.prop == 'IsTeacher'">
                                        <span class="ml-16">{{ scope.row[item.prop]?'是':'否' }}</span>
                                    </span>
                                    <!-- <span v-else-if="item.prop == 'UserStatus'">
                    <span class="account-status" :style="{
                        color: getStatus(scope.row[item.prop]).color,
                        background: getStatus(scope.row[item.prop]).bgc,
                      }">
                      {{ scope.row['UserStatus_V'] }}</span>
                  </span> -->
                                    <span v-else>
                                        {{ scope.row[item.prop] }}
                                    </span>
                                </template>
                            </el-table-column>
                        </template>
                    </el-table>
                </div>
            </div>
            <pagination layout="prev, pager, next" v-show="total > 0" :total="total" :page.sync="queryParams.PageIndex" :limit.sync="queryParams.PageSize" @pagination="getList">
            </pagination>
        </div>
    </div>
</template>

<script>
    import Pagination from '@/components/Pagination';
    import { batchDeleteOrgTeacher, registerTeacherUser, changeUserStatus } from '@/api/organization';
    import { getPageList, deleteStaff } from "@/api/enterprise/enterpriseStaff";
    export default {
        components: {
            Pagination,
        },
        props: {
            currentOrg: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                loading: false,
                tableConfig: [
                    // { label: '头像', prop: 'Avator', width: 140 },
                    { label: '姓名', prop: 'Name', width: 120 },
                    // { label: '性别', prop: 'SexStr', width: 60 },
                    // { label: '电话', prop: 'Phone', width: 120 },
                    // { label: '导师类型', prop: 'TypeName', width: 100 },
                    { label: '部门', prop: 'OrganizationName', width: 150 },
                    { label: '职称', prop: 'Title', width: 150 },
                    { label: '职位', prop: 'Position', width: 150 },
                    { label: '是否老师', prop: 'IsTeacher', width: 80 },
                    // { label: '最高学历', prop: 'Education', width: 80 },
                    // { label: '任教年份', prop: 'TeachYear', width: 80 },
                    // { label: '账户状态', prop: 'UserStatus', width: 100 },
                    { label: '操作', prop: 'Oprate', width: 150 },
                ],
                dataList: [],
                queryParams: {
                    KeyWord: '',
                    OwnerType: null,
                    PageSize: 10,
                    PageIndex: 1,
                },
                userList: [],
                total: 0,
                ownerTypeOptions: [{
                    value: null,
                    label: '全部状态',
                }, ],
            };
        },
        watch: {
            currentOrg: {
                handler(val) {
                    this.queryParams = {
                        KeyWord: '',
                        OwnerType: null,
                        PageSize: 10,
                        PageIndex: 1,
                    };
                    val && this.currentOrg.Id && this.initialize();
                },
            },
        },
        mounted() {
            this.currentOrg && this.currentOrg.Id && this.initialize();
        },
        methods: {
            initialize() {
                this.queryParams.PageIndex = 1;
                this.getList();
            },
            async getList() {
                try {
                    this.loading = true;
                    const res = await getPageList({
                        ...this.queryParams,
                        OrganizationId: this.currentOrg.Id,
                    });
                    this.dataList = res.Result.Data;
                    this.total = res.Result.TotalCount || 0;
                } catch (error) {
                    console.log('error: ', error);
                } finally {
                    this.loading = false;
                }
            },
            handleSelectionChange(val) {
                this.userList = val;
            },
            handleCreateAccount() {
                const ids = this.userList.map((el) => el.TeacherOrganizationId);
                this.createAccount(ids);
            },
            async createAccount(ids) {
                try {
                    this.loading = true;
                    const res = await registerTeacherUser({ ids });
                    if (res.IsSuccess) {
                        this.$message.success('生成成功');
                        this.getList();
                    } else {
                        this.$message.error(res.Message || '生成失败');
                    }
                } catch (error) {
                    console.log('error: ', error);
                } finally {
                    this.loading = false;
                }
            },
            handleDelete({ Id, Name }) {
                this.$confirm(`确定要删除 【${Name}】 吗?删除后将无法恢复！`, '警告', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    })
                    .then(async () => {
                        try {
                            this.loading = true;
                            const res = await deleteStaff(Id);
                            if (res.IsSuccess) {
                                this.$message.success('删除成功');
                                this.dataList = [];
                                this.total = 0;
                                this.getList();
                            } else {
                                this.$message.error(res.Message || '删除失败');
                            }
                        } catch (error) {
                            console.log('error: ', error);
                        } finally {
                            this.loading = false;
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            },
            getStatus(status) {
                switch (status) {
                    case 1:
                        return {
                            color: '#FF6010',
                                bgc: 'rgba(255, 96, 16, 0.1)',
                                text: '已禁用',
                                oprateBtnText: '启用账户',
                        };
                    case -1:
                        return {
                            color: '#999',
                                bgc: 'rgba(153, 153, 153, 0.1)',
                                text: '未激活',
                                oprateBtnText: '生成账户',
                        };
                    case 0:
                        return {
                            color: '#598DF3',
                                bgc: 'rgba(1, 188, 119, 0.1)',
                                text: '已开启',
                                oprateBtnText: '禁用账户',
                        };
                    default:
                        return {
                            color: '#999',
                                bgc: 'rgba(153, 153, 153, 0.1)',
                                text: '未激活',
                        };
                }
            },
            handleOprateAccount(row) {
                switch (row['UserStatus']) {
                    case 1:
                        console.log('启用账户');
                        this.changeAccountStatus({
                            id: row.Id,
                            status: 0,
                        });
                        return;
                    case -1:
                        console.log('生成账户');
                        this.createAccount(row.TeacherOrganizationId);
                        return;
                    case 0:
                        console.log('禁用账户');
                        this.changeAccountStatus({
                            id: row.Id,
                            status: 1,
                        });
                        return;
                    default:
                        return;
                }
            },
            async changeAccountStatus(data) {
                try {
                    this.loading = true;
                    const res = await changeUserStatus(data);
                    if (res.IsSuccess) {
                        this.$message.success('操作成功');
                        this.getList();
                    } else {
                        this.$message.error(res.Message || '操作失败');
                    }
                } catch (error) {
                    console.log('error: ', error);
                } finally {
                    this.loading = false;
                }
            },
            handleAdd() {
                this.$router.push('/enterprise/staff/create?orgId=' + this.currentOrg.Id);
            },
            handleEdit(id) {
                this.$router.push(
                    '/enterprise/staff/edit?orgId=' + this.currentOrg.Id + '&id=' + id
                );
            },
            handleView(id) {
                this.$router.push(
                    '/enterprise/staff/view?orgId=' + this.currentOrg.Id + '&id=' + id
                );
            },
            handleImport() {
                this.$router.push('/enterprise/staff/import?orgId=' + this.currentOrg.Id);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .teacher-container {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;

        .content-wrap {
            box-sizing: border-box;
            // padding: 24px;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;

            .header {
                display: inline-flex;
                align-items: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #999999;

                .title {
                    font-size: 18px;
                    color: #666666;
                    line-height: 25px;
                }
            }

            .params {
                margin: 16px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    display: flex;
                    align-items: center;

                    .label {
                        white-space: nowrap;
                    }
                }
            }

            .table-container {
                flex: 1;
                height: 100%;
                position: relative;

                .table-content {
                    position: absolute;
                    width: 100%;
                    height: 100%;

                    .info {
                        display: inline-flex;
                        align-items: center;
                    }

                    .account-status {
                        padding: 4px 5px;
                        border-radius: 14px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                    }
                }
            }
        }

        .btn-delete {
            color: #ff6600;
            border-color: #ff6600;

            &:hover {
                color: #f56c6c;
                border-color: #f56c6c;
                background: rgba($color: #f56c6c, $alpha: 0.1);
            }
        }

        .is-disabled {
            color: #c0c4cc;
            cursor: not-allowed;
            background-image: none;
            background-color: #ffffff;
            border-color: #e6ebf5;

            &:hover {
                color: #c0c4cc;
                background-color: #ffffff;
                border-color: #e6ebf5;
            }
        }
    }
</style>