<template>
  <div class="organization-container">
    <div class="custom-tree-container">
      <custom-tree v-loading="treeLoading" :treeData="treeData" :highlight="true" :expandedKeys="expandedKeys" @select="handleSelect">
        <template slot-scope="{ data }">
          <el-dropdown @visible-change="selectedRowChanged(data)" trigger="click">
            <i class="iconfont iconjichushuju-gengduo"></i>
            <el-dropdown-menu slot="dropdown">
              <!--学院-->
              <!-- <el-dropdown-item v-if="canAddCollege">
                <span @click="handleAddCollege(data)">新增学院</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canEditCollege">
                <span @click="handleEditCollege(data)">编辑学院</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canDelCollege" class="dropdown-delete">
                <span @click="handleDeleteCollege(data)">删除学院</span>
              </el-dropdown-item> -->
              <!--专业-->
              <!-- <el-dropdown-item v-if="canAddMajor" :divided="canAddCollege || canEditCollege">
                <span @click="handleAddMajor(data)">新增专业</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canEditMajor">
                <span @click="handleEditMajor(data)">编辑专业</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canDelMajor" class="dropdown-delete">
                <span @click="handleDeleteMajor(data)">删除专业</span>
              </el-dropdown-item> -->
              <!--班级-->
              <!-- <el-dropdown-item v-if="canAddClass" :divided="canEditMajor || canAddMajor">
                <span @click="handleAddClass(data)">新增班级</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canEditClass">
                <span @click="handleEditClass(data)">编辑班级</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canDelClass" class="dropdown-delete">
                <span @click="handleDeleteClass(data)">删除班级</span>
              </el-dropdown-item> -->
              <el-dropdown-item v-if="canAddNode" :divided="false">
                <span @click="handleAddNode(data)">新增部门</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canEditNode">
                <span @click="handleEditNode(data)">编辑部门</span>
              </el-dropdown-item>
              <el-dropdown-item v-if="canDelNode" class="dropdown-delete">
                <span @click="handleDeleteNode(data)">删除部门</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </custom-tree>
      <!-- <div class="tree-footer" v-if="!treeData.length">
        <div class="item" @click="handleAddSchoolAndCollege">新建部门</div>
      </div> -->
    </div>
    <div class="content-wrap">
      <list :currentOrg="currentOrg" />
      <!-- <el-tabs v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane
          :key="index"
          v-for="(item, index) in tabsList"
          :label="item.label"
          :name="item.name"
        >
        
          <component
            v-if="activeName == item.name"
            :is="item.name"
            :currentOrg="currentOrg"
          ></component>
        </el-tab-pane>
      </el-tabs> -->
    </div>
    <el-dialog name="schoolDialog" title="添加院校" :visible.sync="schoolDialogVisible" width="40%" append-to-body :before-close="schoolDialogClose">
      <el-form v-loading="dialogLoading" ref="schoolForm" :model="schoolFormData" :rules="rules" label-width="100px" disabled>
        <el-form-item prop="Name" label="学校名称：">
          <el-input placeholder="请输入学校名称" v-model="schoolFormData.Name" size="large"></el-input>
        </el-form-item>
        <el-form-item prop="TypeId" label="节点类型：">
          <el-select v-model="schoolFormData.TypeId" filterable placeholder="请选择节点类型" style="width:100%">
            <el-option v-for="item in typeOptions" :key="item.Id" :label="item.Name" :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="large" @click="schoolDialogClose">取 消</el-button>
        <el-button type="primary" size="large" @click="schoolsubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog name="nodeDialog" :title="nodeTitle" :visible.sync="nodeDialogVisible" width="40%" append-to-body :before-close="DialogClose">
      <el-form ref="nodeForm" v-loading="dialogLoading" :model="nodeFormData" :rules="rules" label-width="100px">
        <el-form-item prop="Name" label="部门：">
          <el-input placeholder="请输入名称" v-model="nodeFormData.Name" size="large"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="large" @click="DialogClose">取 消</el-button>
        <el-button type="primary" size="large" @click="nodesubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <collegeDialog @DialogClosed="DialogClosed" ref="collegeDialogRef" />
    <majorDialog @DialogClosed="DialogClosed" ref="majorDialogRef" />
    <classInfoDialog @DialogClosed="DialogClosed" ref="classDialogRef" /> -->
  </div>
</template>

<script>
import CustomTree from '@/components/Common/CustomTree';
// import Pagination from '@/components/base/Pagination';
// import Teacher from './teacher';
// import Student from './student';
import contentTools from '@/mixins/content-tools';
import store from '@/store';
import List from "./list.vue";
import {
  // insertOrganization,
  // updateOrganization,
  deleteOrganization,
  getTree,
  getMaxOrgEntityLevel,
  getLastParentNodeWithEntity,
} from '@/api/organization';
import { getOrganization,updateOrganization,  insertOrganization, } from "@/api/enterprise/enterpriseStaff";
// import { getSchoolInfo } from '@/api/school';
// import { getMajorListBySchoolId } from '@/api/major';
// import { getCollegeList } from '@/api/college';
// import { getClassList } from '@/api/classInfo';
// import collegeDialog from './components/collegeDialog.vue';
// import majorDialog from './components/majorDialog.vue';
// import classInfoDialog from './components/classDialog.vue';
export default {
  components: {
    CustomTree,
    List
    // Pagination,
    // Teacher,
    // Student,
    // collegeDialog,
    // majorDialog,
    // classInfoDialog,
  },
  data() {
    return {
      id: store.getters.currentId,
      schoolId: '',
      schoolInfo: {},
      loading: false,
      treeLoading: false,
      activeName: null,
      tabsList: [
        { label: '老师管理', name: 'teacher' },
      ],
      treeData: [],
      expandedKeys: [],
      currentOrg: null,
      dataList: [],
      queryParams: {
        KeyWord: '',
        PageSize: 10,
        PageIndex: 1,
      },
      total: 0,
      dialogLoading: false,
      schoolDialogVisible: false,
      schoolFormData: {},
      nodeTitle: '',
      nodeDialogVisible: false,
      nodeFormData: {},
      rules: {
        Name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        TypeId: [
          { required: true, message: '请选择节点类型', trigger: 'blur' },
        ],
        Level: [{ required: true, message: '请输入层级', trigger: 'blur' }],
        TargetId: [
          { required: true, message: '请选择关联实体', trigger: 'blur' },
        ],
      },
      typeOptions: [
        // { Id: "school", Name: "学校" },
        { Id: 'college', Name: '学院', Key: 2 },
        { Id: 'major', Name: '专业', Key: 3 },
        { Id: 'class', Name: '班级', Key: 4 },
        { Id: 'custom', Name: '自定义', Key: 10 },
      ],
      validTypeOptions: [],
      //学院、专业、班级
      collegeList: [],
      classList: [],
      majorList: [],
      collegeDialogVisible: false,
      classDialogVisible: false,
      //节点的下拉按钮可用性
      canAddNode: false,
      canEditNode: false,
      canDelNode: false,
      canAddCollege: false,
      canEditCollege: false,
      canDelCollege: false,
      canAddMajor: false,
      canEditMajor: false,
      canDelMajor: false,
      canAddClass: false,
      canEditClass: false,
      canDelClass: false,
    };
  },
  computed: {},
  mixins: [contentTools],
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getTree();
        this.$nextTick(async () => {
          await Promise.all([
            this.getTree(),
            this.getMajorList(),
            this.getCollegeList(),
            this.getClassList(),
          ]);
        });
        await this.setActive();
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.loading = false;
      }
    },
    async setActive() {
      const { query } = this.$route;
      if (query && query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = this.tabsList[0].name;
      }
    },
    async getMajorList() {
      // try {
      //   const res = await getMajorListBySchoolId(this.id);
      //   this.majorList = res.Result;
      // } catch (error) {
      //   console.log('error: ', error);
      // }
    },
    async getCollegeList() {
      // try {
      //   const res = await getCollegeList(this.id);
      //   this.collegeList = res.Result;
      // } catch (error) {
      //   console.log('error: ', error);
      // }
    },
    async getClassList() {
      // try {
      //   const res = await getClassList(this.id);
      //   this.classList = res.Result;
      // } catch (error) {
      //   console.log('error: ', error);
      // }
    },
    handleEntityChanged(id) {
      this.nodeFormData.Name =
        this.realOptions.filter((el) => el.Id == id)[0].Name ||
        this.realOptions.filter((el) => el.Id == id)[0].MajorBase;
    },
    //弹框 新增一个实体，弹框返回新增实体的ID
    async handleInsertEntity() {
      switch (this.nodeFormData.TypeId) {
        case 'college':
          this.$refs.collegeDialogRef.open();
          break;
        case 'major':
          this.$refs.majorDialogRef.open();
          break;
        case 'class':
          this.$refs.classDialogRef.open();
          break;
        default:
          this.$message.error('不支持的实体类型');
          break;
      }
    },
    async InsertedEntity() {
      await this.refrushEntityList();
    },
    //弹窗关闭
    async DialogClosed() {
      this.getTree();
      this.$nextTick(() => {
        this.expandedKeys = [this.nodeFormData.ParentId];
        this.currentOrg = this.nodeFormData;
      });
    },
    async refrushEntityList() {
      try {
        this.loading = true;
        // await Promise.all([
        //   this.getMajorList(),
        //   this.getCollegeList(),
        //   this.getClassList(),
        // ]);
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.loading = false;
      }
    },
    async getTree() {
      try {
        this.treeLoading = true;
        // const res = await getTree();
        const res = await getOrganization();
        this.treeData = res.Result;
        if (this.treeData.length && !this.expandedKeys.length) {
          this.expandedKeys = [this.treeData[0].Id];
          this.currentOrg = this.treeData[0];
        }
        if (this.treeData.length == 1) {
          //只有一个根节点，即学校
          this.id = this.treeData[0].TargetId;
        }
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.treeLoading = false;
      }
    },
    handleClickTab(tab) {
      if (this.activeName == tab.name) return;
      this.$router.replace('/school/organization?active=' + tab.name);
      // this.activeName = tab.name;
    },
    handleSelect(data) {
      this.currentOrg = data;
    },
    getList() { },
    async handleAddSchoolAndCollege() {
      if (this.treeData.length) {
        await this.handleAddNode();
      } else {
        await this.handleAddSchool();
      }
    },
    async handleAddNode(data) {
      this.nodeTitle = '新增部门';
      this.nodeDialogVisible = true;
      // 根据当前节点，获取可以新增的实体类型
      this.$nextTick(() => {
        this.nodeFormData = {
          TargetId: '',
          ParentId: data.Id,
          TypeId: '',
          Name: '',
          // Level: 2
        };
      });
    },
    async handleEditNode(data) {
      this.nodeTitle = '编辑部门';
      this.nodeDialogVisible = true;
      this.$nextTick(() => {
        this.nodeFormData = Object.assign({}, data);
      });
    },
    //处理当前节点的学校归属
    handleCurrentNodeSchoolBelong() {
      if (this.id != undefined && this.id != '') {
        this.schoolId = this.id; //学校用户登陆的
      } else {
        this.$message.error('您不是学校用户，暂无法添加实体节点');
      }
    },
    handleDeleteNode(data) {
      this.$confirm('确定要删除所选数据吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            this.loading = true;
            const res = await deleteOrganization(data.Id);
            if (res.IsSuccess) {
              this.$message.success('操作成功');
              this.getTree();
              this.$nextTick(() => {
                this.expandedKeys = [data.ParentId];
                this.currentOrg = data;
              });
            } else {
              this.$message.error(res.Message || '操作失败');
            }
          } catch (error) {
            console.log('error: ', error);
          } finally {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    DialogClose() {
      this.nodeDialogVisible = false;
      this.$nextTick(() => {
        this.$refs.nodeForm.resetFields();
      });
    },
    nodesubmit() {
      this.$refs.nodeForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogLoading = true;
            var res;
            if (this.nodeTitle == '新增部门') {
              res = await insertOrganization(this.nodeFormData);
            } else {
              res = await updateOrganization(this.nodeFormData);
            }
            if (res.IsSuccess) {
              this.$message.success('操作成功');
              this.DialogClose();
              this.getTree();
              this.$nextTick(() => {
                this.expandedKeys = [this.nodeFormData.ParentId];
                this.currentOrg = this.nodeFormData;
              });
            } else {
              this.$message.error(res.Message || '操作失败');
            }
          } catch (err) {
            console.log(err);
          } finally {
            this.dialogLoading = false;
          }
        } else {
          this.$message({
            type: 'error',
            message: '表单填写错误',
          });
          return;
        }
      });
    },
    async getSchoolInfo() {
      // const res = await getSchoolInfo(this.id);
      // if (res.IsSuccess) {
      //   this.schoolInfo = Object.assign({}, res.Result);
      // }
    },
    async handleAddSchool() {
      this.schoolDialogVisible = true;
      await this.getSchoolInfo();
      this.$nextTick(() => {
        this.schoolFormData = {
          TargetId: this.id,
          TypeId: 'school',
          Name: this.schoolInfo.Name,
          Level: 1,
        };
      });
    },
    schoolDialogClose() {
      this.schoolDialogVisible = false;
      this.$nextTick(() => {
        this.$refs.schoolForm.resetFields();
      });
    },
    schoolsubmit() {
      this.$refs.schoolForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogLoading = true;
            const res = await insertOrganization(this.schoolFormData);
            if (res.IsSuccess) {
              this.$message.success('添加成功');
              this.schoolDialogClose();
              this.getTree();
            } else {
              this.$message.error(res.Message || '添加失败');
            }
          } catch (err) {
            console.log(err);
          } finally {
            this.dialogLoading = false;
          }
        } else {
          this.$message({
            type: 'error',
            message: '表单填写错误',
          });
          return;
        }
      });
    },
    // 选中行变化时，更改menuitem可见性
    async selectedRowChanged(data) {
      this.canAddNode = false;
      this.canEditNode = false;
      this.canDelNode = false;
      this.canAddCollege = false;
      this.canEditCollege = false;
      this.canDelCollege = false;
      this.canAddMajor = false;
      this.canEditMajor = false;
      this.canDelMajor = false;
      this.canAddClass = false;
      this.canEditClass = false;
      this.canDelClass = false;


      this.canAddNode = true;
      if (data.Level!=1&&data.ParentId) {
        this.canEditNode = true;
      }
      console.log(`data`, data)
      return;
      // try {
      //   var res = await getMaxOrgEntityLevel({ id: data.Id });
      //   if (res.IsSuccess) {
      //     var level = res.Result;
      //     switch (level) {
      //       case 1:
      //         //学校节点 新增专业、新增学院、新增普通节点
      //         this.canAddNode = true;
      //         this.canAddCollege = true;
      //         this.canAddMajor = true;
      //         if (data.ParentId && !data.TargetId) {
      //           this.canEditNode = true;
      //           this.canDelNode = true;
      //         }
      //         break;
      //       case 2:
      //         //学院节点 编辑、删除学院，新增专业，新增普通节点
      //         this.canAddNode = true;
      //         this.canAddMajor = true;
      //         if (data.TargetId && data.TypeId == 'college') {
      //           this.canEditCollege = true;
      //           this.canDelCollege = true;
      //         } else {
      //           this.canEditNode = true;
      //           this.canDelNode = true;
      //         }
      //         break;
      //       case 3:
      //         //专业节点， 编辑、删除专业， 新增班级或新增普通节点
      //         this.canAddNode = true;
      //         this.canAddClass = true;
      //         if (data.TargetId && data.TypeId == 'major') {
      //           this.canEditMajor = true;
      //           this.canDelMajor = true;
      //         } else {
      //           this.canEditNode = true;
      //           this.canDelNode = true;
      //         }
      //         break;
      //       case 5:
      //         //班级节点
      //         this.canAddNode = true;
      //         if (data.TargetId && data.TypeId == 'class') {
      //           this.canEditClass = true;
      //           this.canDelClass = true;
      //         } else {
      //           this.canEditNode = true;
      //           this.canDelNode = true;
      //         }
      //         break;
      //       default:
      //         return;
      //     }
      //   } else {
      //     return;
      //   }
      // } catch (error) {
      //   console.log(error);
      //   // this.$message.error(error);
      // }
    },
    //新增学院
    async handleAddCollege(data) {
      this.handleCurrentNodeSchoolBelong();
      this.$refs.collegeDialogRef.open(this.schoolId, data, false);
    },
    async handleEditCollege(data) {
      this.handleCurrentNodeSchoolBelong();
      this.$refs.collegeDialogRef.open(this.schoolId, data, true);
    },
    handleDeleteCollege(data) {
      this.handleDeleteNode(data);
      //删除学院 todo
    },
    //专业
    async handleAddMajor(data) {
      this.handleCurrentNodeSchoolBelong();
      this.$refs.majorDialogRef.open(this.schoolId, data, false);
    },
    async handleEditMajor(data) {
      this.handleCurrentNodeSchoolBelong();
      this.$refs.majorDialogRef.open(this.schoolId, data, true);
    },
    handleDeleteMajor(data) {
      this.handleDeleteNode(data);
      //删除专业 todo
    },
    //班级
    async handleAddClass(data) {
      this.handleCurrentNodeSchoolBelong();
      //获取上级的专业ID
      var majorId = '';
      try {
        var res = await getLastParentNodeWithEntity({
          nodeId: data.Id,
          typeId: 'major',
          containsCurrentNode: true,
        });
        if (res.IsSuccess && res.Result.TargetId) {
          majorId = res.Result.TargetId;
        }
      } catch (err) {
        this.$message.error('获取上级专业ID异常', err);
        return;
      }
      this.$refs.classDialogRef.open(this.schoolId, data, majorId, false);
    },
    async handleEditClass(data) {
      this.handleCurrentNodeSchoolBelong();
      this.$refs.classDialogRef.open(this.schoolId, data, '', true);
    },
    handleDeleteClass(data) {
      this.handleDeleteNode(data);
      //删除班级 todo
    },
  },
};
</script>

<style lang="scss" scoped>
.organization-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .custom-tree-container {
    min-width: 276px;
    height: 100%;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;

    .tree-footer {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08), -1px 0px 0px 0px #eeeeee;

      .item {
        padding: 8px 16px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        border-radius: 4px;
        border: 1px solid #dddddd;

        &:nth-of-type(2) {
          margin-left: 8px;
        }

        &:hover {
          cursor: pointer;
          color: #598DF3;
          background: rgba($color: #598DF3, $alpha: 0.1);
        }
      }
    }
  }

  .content-wrap {
    box-sizing: border-box;
    padding: 24px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    ::v-deep {
      .el-tabs {
        height: calc(100% - 40px);
      }

      .el-tabs__content {
        height: 100%;
      }

      .el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  /deep/ .el-tabs__nav-wrap::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #dfe4ed;
    z-index: 1;
  }
}

.dropdown-delete {
  &:hover {
    cursor: pointer;
    color: #ff6600;
    background: rgba($color: #ff6600, $alpha: 0.1);
  }
}
</style>
